<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle :title="pageTitle">
            <button-group
                v-if="series.length > 0 && contract && contract.assets.length > 1"
                :options="deviceOptions"
                :active-item="selectedDevice"
                shadow
                @change-filter="changeSeries"
            />
            <router-link to="/contracts">
                <TwButton variant="light"
                    ><chevron-left-icon class="w-5 h-5 mr-2" />Back to Flexibility Contracts</TwButton
                >
            </router-link>
        </PageTitle>
        <div class="flex flex-1 overflow-hidden">
            <div class="flex flex-col flex-1 p-8 overflow-auto" v-if="!loading && series.length > 0">
                <div class="p-6 bg-white border rounded-md border-neutral-200">
                    <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                        Flexibility Timeline
                    </div>
                    <apexchart type="line" height="230" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div class="p-6 mt-4 bg-white border rounded-md border-neutral-200">
                    <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                        Contract Performance Overview
                    </div>
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr class="h-10 text-center">
                                <th>
                                    <span v-if="selectedDevice === '*'">Device ID</span>
                                    <span v-else>Event ID</span>
                                </th>
                                <th>Baseline (KW)</th>
                                <th>Actual (KW)</th>
                                <th>Request (KW)</th>
                                <th>Offer (KW)</th>
                                <th>Power Deficiency (KW)</th>
                                <th>Utilization (KW)</th>
                                <th>Availability (KW)</th>
                                <th>Penalty (KW)</th>
                                <th>Settlement (KW)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, idx) in performanceData" :key="idx" class="h-10 text-center">
                                <td>
                                    <span v-if="selectedDevice === '*'">{{ row.deviceID }}</span>
                                    <span v-else>{{ row.eventID }}</span>
                                </td>
                                <td>
                                    <span v-if="row.baseline !== null">{{ row.baseline.toFixed(2) }}</span>
                                    <span v-else>&#8212;</span>
                                </td>
                                <td>{{ row.actual.toFixed(2) }}</td>
                                <td>
                                    <span v-if="row.request !== null">{{ row.request.toFixed(2) }}</span>
                                    <span v-else>&#8212;</span>
                                </td>
                                <td>{{ row.offer.toFixed(2) }}</td>
                                <td>{{ row.powerDeficiency.toFixed(2) }}</td>
                                <td>{{ row.utilization.toFixed(2) }}</td>
                                <td>{{ row.availability.toFixed(2) }}</td>
                                <td>{{ row.penalty.toFixed(2) }}</td>
                                <td>{{ row.settlement.toFixed(2) }}</td>
                            </tr>
                            <tr class="h-10 font-bold text-center text-primary-500">
                                <td>Total</td>
                                <td>
                                    <span
                                        v-if="
                                            performanceData.filter(row => row.baseline === null).length ===
                                                performanceData.length
                                        "
                                    >
                                        &#8212;
                                    </span>
                                    <span v-else>{{ getTotal('baseline').toFixed(2) }}</span>
                                </td>
                                <td>{{ getTotal('actual').toFixed(2) }}</td>
                                <td>
                                    <span
                                        v-if="
                                            performanceData.filter(row => row.request === null).length ===
                                                performanceData.length
                                        "
                                    >
                                        &#8212;
                                    </span>
                                    <span v-else>{{ getTotal('request').toFixed(2) }}</span>
                                </td>
                                <td>{{ getTotal('offer').toFixed(2) }}</td>
                                <td>{{ getTotal('powerDeficiency').toFixed(2) }}</td>
                                <td>{{ getTotal('utilization').toFixed(2) }}</td>
                                <td>{{ getTotal('availability').toFixed(2) }}</td>
                                <td>{{ getTotal('penalty').toFixed(2) }}</td>
                                <td>{{ getTotal('settlement').toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex flex-col items-center justify-center flex-grow" v-else-if="series.length > 0">
                <fulfilling-bouncing-circle-spinner
                    class="ml-4"
                    :animation-duration="3000"
                    :size="40"
                    color="#1d746a"
                />
                <span class="mt-4 text-neutral-500">Calculating performance details. Please wait...</span>
            </div>
            <div class="flex flex-col justify-center flex-grow" v-else>
                <div class="content-center max-w-md px-8 mx-auto">
                    <svg-image class="p-4 text-primary-600" src="/img/empty.svg" />
                </div>
                <div class="mx-4">
                    <h1 class="mt-4 text-xl font-medium text-center text-primary-900 md:text-xl sm:mt-8">
                        The specific contract has no performance data at the moment.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import dayjs from 'dayjs';
import { PageTitle, ButtonGroup, SvgImage, TwButton } from '@/components';
import { ContractsAPI } from '@/api';
import VueApexCharts from 'vue3-apexcharts';
import { ChevronLeftIcon } from '@heroicons/vue/outline';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';
import * as R from 'ramda';

export default defineComponent({
    name: 'PerformanceOverview',
    components: {
        PageTitle,
        apexchart: VueApexCharts,
        FulfillingBouncingCircleSpinner,
        ButtonGroup,
        SvgImage,
        TwButton,
        ChevronLeftIcon,
    },
    props: {
        id: {
            type: String,
        },
    },
    setup(props) {
        const contract = ref(null);
        const loading = ref(false);
        const signals = ref([]);
        const selectedDevice = ref('*');
        const deviceOptions = ref([]);
        // TODO: fix this when we have the retrieval queries
        // const series = ref([]);
        const series = ref([
            {
                name: 'Offer',
                data: [
                    [1633005727, 28],
                    [1633092127, 15],
                    [1633178527, 20],
                    [1633264927, 18],
                    [1633351327, 7],
                ],
            },
            {
                name: 'Request',
                data: [
                    [1633005727, 0],
                    [1633092127, 0],
                    [1633178527, 0],
                    [1633264927, 0],
                    [1633351327, 0],
                ],
            },
        ]);

        const chartOptions = {
            chart: {
                type: 'area',
                stacked: false,
                height: 300,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                },
            },
            theme: {
                mode: 'light',
                palette: 'palette5',
            },
            stroke: {
                width: 3,
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 4,
            },
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'Time',
                },
            },
            yaxis: {
                title: {
                    text: 'Flexibility (KW)',
                },
                labels: {
                    formatter: function(val) {
                        return val?.toFixed(2);
                    },
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy HH:mm',
                },
            },
            legend: {
                position: 'top',
            },
        };

        const pageTitle = computed(() =>
            contract.value ? `Performance Overview of Contract #${contract.value.id}` : 'Performance Overview',
        );

        ContractsAPI.get(props.id).then(res => {
            contract.value = res.data;
        });

        const pushToSeries = (name, data) => {
            const processedData = [];
            let eventid = data.length > 0 ? data[0][2] : null;
            data.forEach(item => {
                if (item[2] !== eventid) {
                    processedData.push([
                        dayjs(item[0])
                            .subtract(1, 'second')
                            .format(),
                        null,
                    ]);
                }
                processedData.push(item.slice(0, 2));
                [eventid] = [item[2]];
            });
            series.value.push({ name, data: processedData });
        };

        const changeSeries = selDevice => {
            series.value = [];
            selectedDevice.value = selDevice;

            const devices =
                selDevice === '*'
                    ? contract.value.assets
                    : [contract.value.assets.find(device => device.uuid === selDevice)];
            devices.forEach(device => {
                const filteredSignals = signals.value.filter(signal => signal.deviceID === String(device.uuid));
                const signalsDescriptions = R.uniq(filteredSignals.map(signal => signal.description));
                if (signalsDescriptions.length > 1) {
                    const downwardsSignals = filteredSignals.filter(signal => signal.description === 'downwards');
                    const upwardsSignals = filteredSignals.filter(signal => signal.description === 'upwards');
                    pushToSeries(
                        `Offer Upwards${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        upwardsSignals.map(item => [item.timestamp, item.offer, item.eventID]),
                    );
                    pushToSeries(
                        `Request Upwards${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        upwardsSignals.map(item => [item.timestamp, item.request, item.eventID]),
                    );
                    pushToSeries(
                        `Offer Downwards${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        downwardsSignals.map(item => [item.timestamp, item.offer, item.eventID]),
                    );
                    pushToSeries(
                        `Request Downwards${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        downwardsSignals.map(item => [item.timestamp, item.request, item.eventID]),
                    );
                } else {
                    pushToSeries(
                        `Offer${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        filteredSignals.map(item => [item.timestamp, item.offer, item.eventID]),
                    );
                    pushToSeries(
                        `Request${selDevice === '*' ? ` of ${device.title}` : ''}`,
                        filteredSignals.map(item => [item.timestamp, item.request, item.eventID]),
                    );
                }
            });
        };

        const getTotal = column => {
            if (column === 'request' || column === 'baseline')
                return performanceData.value
                    .filter(row => row[column] !== null)
                    .map(row => row[column])
                    .reduce((a, b) => a + b, 0);
            return performanceData.value.map(row => row[column]).reduce((a, b) => a + b, 0);
        };

        const getSignals = async () => {
            // TODO: fix this when we have the retrieval queries (uncomment the code below)
            // loading.value = true;
            // ContractsAPI.get(props.id)
            //     .then(async res => {
            //         contract.value = res.data;
            //         ContractsAPI.getSignals(contract.value.uuid).then(res => {
            //             signals.value = res.data;
            //             if (contract.value.assets.length > 1) {
            //                 deviceOptions.value.push({ text: 'All', value: '*' });
            //             } else {
            //                 selectedDevice.value = contract.value.assets[0].uuid;
            //             }
            //             contract.value.assets.forEach(asset => {
            //                 deviceOptions.value.push({ text: asset.title, value: asset.uuid });
            //             });
            //             changeSeries(selectedDevice.value);
            //             loading.value = false;
            //         });
            //     })
            //     .catch(() => (loading.value = false));
        };

        getSignals();

        const performanceData = computed(() => {
            const result = [];
            const groupBy = selectedDevice.value === '*' ? 'deviceID' : 'eventID';

            // #TODO: fix this when we have the retrieval queries (uncomment the code below)
            // const filteredSignals =
            //     selectedDevice.value === '*'
            //         ? signals.value
            //         : signals.value.filter(signal => signal.deviceID === selectedDevice.value);

            // #TODO: remove this when we have the retrieval queries
            const filteredSignals = [
                {
                    eventID: 1,
                    baseline: 1,
                    actual: 1,
                    request: 1,
                    offer: 1,
                    powerDeficiency: 1,
                    utilization: 1,
                    availability: 1,
                    penalty: 1,
                    settlement: 1,
                    deviceID: '1c8559cd-21e7-11ec-9d8a-960000500b98',
                },
            ];
            filteredSignals.reduce((res, value) => {
                if (!res[value[groupBy]]) {
                    res[value[groupBy]] = {
                        eventID: value.eventID,
                        baseline: 0,
                        actual: 0,
                        request: 0,
                        offer: 0,
                        powerDeficiency: 0,
                        utilization: 0,
                        availability: 0,
                        penalty: 0,
                        settlement: 0,
                        deviceID: value.deviceID,
                    };
                    result.push(res[value[groupBy]]);
                }
                if (value.baseline === null) res[value[groupBy]].baseline = null;
                else res[value[groupBy]].baseline += value.baseline;
                res[value[groupBy]].actual += value.actual;
                if (value.request === null) res[value[groupBy]].request = null;
                else res[value[groupBy]].request += value.request;
                res[value[groupBy]].offer += value.offer;
                res[value[groupBy]].powerDeficiency += value.powerDeficiency;
                res[value[groupBy]].utilization += value.utilization;
                res[value[groupBy]].availability += value.availability;
                res[value[groupBy]].penalty += value.penalty;
                res[value[groupBy]].settlement += value.settlement;
                return res;
            }, {});
            return result;
        });

        return {
            contract,
            pageTitle,
            chartOptions,
            series,
            performanceData,
            loading,
            signals,
            selectedDevice,
            deviceOptions,
            getTotal,
            changeSeries,
        };
    },
});
</script>
