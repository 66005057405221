<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle title="Flexibility Contracts List" :loading="loading">
            <SortedSearch
                v-model="search"
                :sortOptions="sortOptions"
                :filterOptions="filterOptions"
                :disabled="!contracts.length"
            />
        </PageTitle>

        <div v-if="contracts && contracts.length && !loading" class="flex flex-col flex-1 overflow-hidden">
            <div v-if="visibleContracts.length > 0" class="flex flex-1 overflow-hidden">
                <div class="flex flex-col flex-grow ">
                    <Scrollbar class="flex flex-col flex-1 px-8 mt-8 mb-8 overflow-auto">
                        <ContractCard
                            v-for="(contract, index) in visibleContracts"
                            :class="{
                                'border-b border-neutral-100': index !== visibleContracts.length - 1,
                                'rounded-b-lg': index === visibleContracts.length - 1,
                                'rounded-t-lg': index === 0,
                            }"
                            :key="contract.id"
                            :contract="contract"
                        />
                    </Scrollbar>
                    <Pagination
                        v-show="filteredContracts.length > pageSize"
                        class="flex w-full px-8 bg-white border-t border-neutral-200"
                        v-model="currentPage"
                        :pageSize="pageSize"
                        :total="filteredContracts.length"
                    />
                </div>
            </div>

            <div class="flex flex-grow" v-else-if="contracts.length > 0">
                <div class="max-w-xl m-auto">
                    <div class="content-center px-8">
                        <svg-image class="p-4 mt-16 lg:mt-32 text-primary-600" src="/img/filter.svg" />
                    </div>
                    <div class="mx-4">
                        <h1 class="mt-8 text-xl font-medium text-center text-primary-900 md:text-xl sm:mt-16">
                            No contracts matched your search!
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-grow" v-else-if="!loading">
            <div class="max-w-xl m-auto">
                <div class="content-center px-8">
                    <svg-image class="p-4 mt-16 lg:mt-32 text-primary-600" src="/img/empty.svg" />
                </div>
                <div class="mx-4">
                    <h1 class="mt-4 text-xl font-medium text-center text-primary-900 md:text-xl sm:mt-8">
                        No contracts found
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PageTitle, SortedSearch, Pagination, Scrollbar, SvgImage } from '@/components';
import { defineComponent, ref } from 'vue';
import { ContractCard } from '../contract/components';
import { useContracts } from '@/composables';

const sortOptions = [
    { key: ['id'], label: 'ID' },
    { key: ['createdAt'], label: 'Created Date' },
    { key: ['flexibilityCapacity'], label: 'Flexibility Capacity' },
];

const filterOptions = [
    { key: 'all', label: 'All' },
    { key: 'active', label: 'Active' },
    { key: 'offer', label: 'Offer' },
    { key: 'negotiation', label: 'Negotiation' },
    { key: 'closed', label: 'Closed' },
    { key: 'rejected', label: 'Rejected' },
];

export default defineComponent({
    name: 'ContractsList',
    components: {
        PageTitle,
        Scrollbar,
        ContractCard,
        SortedSearch,
        Pagination,
        SvgImage,
    },
    setup() {
        const search = ref({ text: '', sortBy: ['createdAt'], sortOrder: 'desc', filterBy: 'all' });
        const pageSize = 10;
        const currentPage = ref(1);

        const { contracts, filteredContracts, visibleContracts, loading } = useContracts(
            pageSize,
            search,
            currentPage,
            true,
        );

        return {
            search,
            sortOptions,
            filterOptions,
            contracts,
            pageSize,
            currentPage,
            visibleContracts,
            filteredContracts,
            loading,
        };
    },
});
</script>
