<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle :title="pageTitle" :loading="loading">
            <template #status>
                <div
                    class="px-2 py-1 ml-4 text-sm capitalize rounded-full"
                    :class="statusClasses(contract.status)"
                    v-if="contract.status"
                >
                    {{ contract.status }}
                </div>
            </template>
            <div class="flex flex-wrap items-center ml-4 space-x-4">
                <TwButton variant="secondary" v-if="!contract.status" @click="confirmAction(sendContract)">
                    <paper-airplane-icon class="w-5 h-5 mr-2" />Send Contract
                </TwButton>
                <template v-else>
                    <TwButton variant="light" v-if="!negotiating" @click="router.push('/contracts')">
                        <chevron-left-icon class="w-5 h-5 mr-2" />
                        <span v-if="id === 'create'">Go</span><span v-else>Back</span
                        ><span class="ml-1">to Flexibility Contracts</span>
                    </TwButton>
                    <template v-if="allowNegotiationActions && !negotiating">
                        <TwButton variant="red" @click="confirmAction(declineContract)">
                            <x-circle-icon class="w-5 h-5 mr-2" />Reject
                        </TwButton>
                        <TwButton v-if="enableNegotiate" variant="yellow" @click="negotiating = true">
                            <pencil-alt-icon class="w-5 h-5 mr-2" />Negotiate
                        </TwButton>
                        <TwButton variant="green" @click="confirmAction(acceptContract)">
                            <check-circle-icon class="w-5 h-5 mr-2" />Accept
                        </TwButton>
                    </template>
                    <template v-else-if="negotiating">
                        <TwButton variant="light" @click="cancelNegotiation">
                            <x-icon class="w-5 h-5 mr-2" />Cancel
                        </TwButton>
                        <TwButton variant="secondary" @click="confirmAction(submitNegotiation)">
                            <check-icon class="w-5 h-5 mr-2" />Submit
                        </TwButton>
                    </template>
                </template>
            </div>
        </PageTitle>
        <Form
            :validation-schema="validationSchema"
            ref="form"
            class="flex flex-col flex-1 overflow-hidden"
            v-if="!loading"
        >
            <div class="flex flex-col flex-1 p-8 overflow-auto">
                <ContractAssets :assets="contract.assets" />

                <div class="grid grid-cols-1 gap-x-4 lg:grid-cols-2">
                    <div class="p-6 mt-4 bg-white border rounded-md border-neutral-200">
                        <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                            Operation Details
                        </div>
                        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <template v-if="!contract.status">
                                <Calendar
                                    uid="startPeriod"
                                    name="startPeriod"
                                    v-model="contract.startPeriod"
                                    label="Start Date"
                                    placeholder="Start Date"
                                    mode="date"
                                    :minDate="new Date()"
                                    :maxDate="contract.endPeriod ? calculateBeforeEndPeriodDate : null"
                                />

                                <Calendar
                                    uid="endPeriod"
                                    name="endPeriod"
                                    v-model="contract.endPeriod"
                                    label="End Date"
                                    placeholder="End Date"
                                    mode="date"
                                    :minDate="contract.startPeriod ? calculateAfterStartPeriodDate : new Date()"
                                />
                            </template>
                            <template v-else>
                                <FormItem
                                    label="Start Date"
                                    :value="dayjs(contract.startPeriod).format('DD/MM/YYYY')"
                                />
                                <FormItem label="End Date" :value="dayjs(contract.endPeriod).format('DD/MM/YYYY')" />
                            </template>
                        </div>
                        <div class="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-3">
                            <InputField
                                v-if="!contract.status"
                                name="flexibilityCapacity"
                                label="Max Flexibility Capacity"
                                v-model="contract.flexibilityCapacity"
                                type="number"
                                trailingText="KW"
                                min="1"
                            />
                            <FormItem
                                v-else
                                label="Max Flexibility Capacity"
                                :contractStatus="contract.status"
                                :value="`${contract.flexibilityCapacity} KW`"
                            />
                            <template v-if="!contract.status || negotiating">
                                <InputField
                                    name="dailyActivations"
                                    label="Daily Activations"
                                    v-model="negotiable.dailyActivations"
                                    type="number"
                                    min="1"
                                />
                                <InputField
                                    name="totalActivations"
                                    label="Total Activations"
                                    v-model="negotiable.totalActivations"
                                    type="number"
                                    min="1"
                                    :max="maxTotalActivations"
                                />
                            </template>
                            <template v-else>
                                <FormItem
                                    label="Daily Activations"
                                    :contractStatus="contract.status"
                                    :value="negotiable.dailyActivations"
                                    :previousValue="previousNegotiations.dailyActivations"
                                />
                                <FormItem
                                    label="Total Activations"
                                    :contractStatus="contract.status"
                                    :value="negotiable.totalActivations"
                                    :previousValue="previousNegotiations.totalActivations"
                                />
                            </template>
                        </div>
                    </div>
                    <div class="p-6 mt-4 bg-white border rounded-md border-neutral-200">
                        <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                            Financial Details
                        </div>
                        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <template v-if="!contract.status || negotiating">
                                <InputField
                                    name="utilization"
                                    label="Utilization"
                                    v-model="negotiable.utilization"
                                    type="number"
                                    trailingText="euro/KWh"
                                    min="1"
                                />
                                <InputField
                                    name="availability"
                                    label="Availability"
                                    v-model="negotiable.availability"
                                    type="number"
                                    trailingText="euro/KWh"
                                    min="1"
                                />
                                <InputField
                                    name="penalty"
                                    label="Penalty"
                                    v-model="negotiable.penalty"
                                    type="number"
                                    trailingText="euro/KWh"
                                    min="1"
                                />
                            </template>
                            <template v-else>
                                <FormItem
                                    label="Utilization"
                                    :contractStatus="contract.status"
                                    :value="`${negotiable.utilization} euro/KWh`"
                                    :previousValue="previousNegotiations.utilization"
                                />
                                <FormItem
                                    label="Availability"
                                    :contractStatus="contract.status"
                                    :value="`${negotiable.availability} euro/KWh`"
                                    :previousValue="previousNegotiations.availability"
                                />
                                <FormItem
                                    label="Penalty"
                                    :contractStatus="contract.status"
                                    :value="`${negotiable.penalty} euro/KWh`"
                                    :previousValue="previousNegotiations.penalty"
                                />
                            </template>
                        </div>
                    </div>
                </div>

                <div class="p-6 mt-4 bg-white border rounded-md border-neutral-200">
                    <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                        Availability Details
                    </div>
                    <div class="flex items-center mb-2 space-x-4 text-sm font-medium tracking-wide text-neutral-600">
                        <div class="w-2/12">Schedule #</div>
                        <div class="w-3/12">Week Day</div>
                        <div class="w-2/12">Start Time</div>
                        <div class="w-2/12">End Time</div>
                        <div class="w-2/12"></div>
                    </div>
                    <div
                        class="flex items-center mb-2 space-x-4"
                        v-for="(schedule, idx) in contract.weekDaysAndTimes"
                        :key="idx"
                    >
                        <div class="w-2/12 text-sm text-neutral-700">Schedule {{ idx + 1 }}</div>
                        <template v-if="!contract.status">
                            <SelectMenu
                                class="w-3/12"
                                placeholder="Select week day"
                                name="weekDay"
                                id="weekDay"
                                :options="weekDayOptions"
                                v-model="schedule.weekDay"
                            />
                            <Calendar
                                class="w-2/12"
                                name="startTime"
                                :uid="`startTime${idx}`"
                                v-model="schedule.startTime"
                                placeholder="Start Time"
                                mode="time"
                            />
                            <Calendar
                                class="w-2/12"
                                name="endTime"
                                :uid="`endTime${idx}`"
                                v-model="schedule.endTime"
                                placeholder="End Time"
                                mode="time"
                            />
                            <a
                                href="javascript:void(0)"
                                class="w-2/12 text-red-500 hover:text-red-600"
                                v-if="idx !== 0"
                                @click="removeSchedule(idx)"
                            >
                                <trash-icon class="w-5 h-5" />
                            </a>
                        </template>
                        <template v-else>
                            <FormItem class="w-3/12" :value="schedule.weekDay" />
                            <FormItem class="w-2/12" :value="formatTime(schedule.startTime)" />
                            <FormItem class="w-2/12" :value="formatTime(schedule.endTime)" />
                        </template>
                    </div>
                    <div class="flex justify-end mt-4" v-if="!contract.status">
                        <TwButton variant="light" @click="addSchedule">
                            <plus-icon class="w-5 h-5 mr-2" /> Add Schedule
                        </TwButton>
                    </div>
                </div>
            </div>
            <div
                class="flex justify-end px-8 py-3 bg-white border-t border-neutral-200"
                v-if="enableDownloadPDF && contract.status"
            >
                <TwButton @click="downloadPDF"> <DownloadIcon class="w-5 h-5 mr-2" /> Download Contract PDF </TwButton>
            </div>
        </Form>
        <WalletModal
            v-if="showUnlockWalletModal"
            @cancel="showUnlockWalletModal = false"
            type="Unlock"
            title="Unlock Ethereum Wallet"
            description="Access to your Ethereum wallet is required before you continue. Please enter your password."
            loadingMessage="Unlocking Wallet..."
            :callback="action"
        />
        <LoadingModal v-if="isBlockchainEnabled && showLoadingModal" :message="modalMessage" />
    </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { Form, defineRule } from 'vee-validate';
import { required, min_value, max_value } from '@vee-validate/rules';
import { PageTitle, Calendar, InputField, SelectMenu, TwButton, FormItem } from '@/components';
import { ContractAssets, WalletModal, LoadingModal } from './components';
import {
    TrashIcon,
    PlusIcon,
    XCircleIcon,
    XIcon,
    CheckCircleIcon,
    CheckIcon,
    PencilAltIcon,
    PaperAirplaneIcon,
    ChevronLeftIcon,
    DownloadIcon,
} from '@heroicons/vue/outline';
import store from '@/store';
import { useRouter } from 'vue-router';
import { ContractStatus, weekDayOptions } from './constants';
import { useToast } from 'vue-toastification';
import { useContracts, useBlockchain, useUtils, useCart } from '@/composables';
import { ContractsAPI } from '@/api';

defineRule('required', required);
defineRule('min_value', min_value);
defineRule('max_value', max_value);

export default defineComponent({
    name: 'Contract',
    props: {
        id: {
            type: String || Number,
        },
    },
    components: {
        PageTitle,
        Form,
        Calendar,
        InputField,
        SelectMenu,
        ContractAssets,
        TrashIcon,
        TwButton,
        PlusIcon,
        PaperAirplaneIcon,
        XCircleIcon,
        XIcon,
        CheckCircleIcon,
        CheckIcon,
        PencilAltIcon,
        ChevronLeftIcon,
        WalletModal,
        LoadingModal,
        FormItem,
        DownloadIcon,
    },
    setup(props) {
        const enableNegotiate = ref(false);
        const enableDownloadPDF = ref(false);
        const isAggregator = store.getters.isAggregator;
        const form = ref();
        const router = useRouter();
        const toast = useToast();
        const negotiating = ref(false);
        const isBlockchainEnabled = ref(!!process.env.VUE_APP_ETH_NODE);
        const action = ref(null);
        const showUnlockWalletModal = ref(false);
        const showLoadingModal = ref(false);
        const loading = ref(false);

        const { clearCart } = useCart();
        const { formatTime } = useUtils();
        const { prepareContractPayload, statusClasses } = useContracts();
        const {
            message: modalMessage,
            createContract,
            negotiate,
            counterOffer,
            accept,
            reject,
            acceptOffer,
            rejectOffer,
        } = useBlockchain();

        const contract = ref({
            assets: [],
            startPeriod: null,
            endPeriod: null,
            flexibilityCapacity: null,
            utilization: [],
            availability: [],
            penalty: [],
            weekDaysAndTimes: [
                {
                    weekDay: null,
                    startTime: {
                        hours: null,
                        minutes: null,
                    },
                    endTime: {
                        hours: null,
                        minutes: null,
                    },
                },
            ],
            totalActivations: [],
            dailyActivations: [],
            status: null,
            ethAddress: null,
            issueTime: null,
        });

        const validationSchema = computed(() => {
            return {
                utilization: 'required|min_value:1',
                availability: 'required|min_value:1',
                penalty: 'required|min_value:1',
                startPeriod: contract.value.status ? null : 'required',
                endPeriod: contract.value.status ? null : 'required',
                flexibilityCapacity: contract.value.status ? null : 'required|min_value:1',
                totalActivations: `required|min_value:1|max_value:${maxTotalActivations.value}`,
                dailyActivations: 'required|min_value:1',
                // weekDay: contract.value.status ? null : 'required',
            };
        });

        const negotiable = reactive({
            utilization: null,
            availability: null,
            penalty: null,
            totalActivations: null,
            dailyActivations: null,
        });

        const previousNegotiations = reactive({
            utilization: null,
            availability: null,
            penalty: null,
            totalActivations: null,
            dailyActivations: null,
        });

        const initializeNegotiations = contract => {
            negotiable.utilization = contract.utilization[contract.utilization.length - 1];
            negotiable.availability = contract.availability[contract.availability.length - 1];
            negotiable.penalty = contract.penalty[contract.penalty.length - 1];
            negotiable.totalActivations = contract.totalActivations[contract.totalActivations.length - 1];
            negotiable.dailyActivations = contract.dailyActivations[contract.dailyActivations.length - 1];

            if (contract.utilization.length > 1) {
                previousNegotiations.utilization = contract.utilization[contract.utilization.length - 2];
                previousNegotiations.availability = contract.availability[contract.availability.length - 2];
                previousNegotiations.penalty = contract.penalty[contract.penalty.length - 2];
                previousNegotiations.totalActivations = contract.totalActivations[contract.totalActivations.length - 2];
                previousNegotiations.dailyActivations = contract.dailyActivations[contract.dailyActivations.length - 2];
            }
        };

        const initializeContract = data => {
            const contract = R.clone(data);
            initializeNegotiations(contract);
            contract.weekDaysAndTimes.forEach(schedule => {
                const startTimeParts = schedule.startTime.split(':');
                const endTimeParts = schedule.endTime.split(':');
                schedule.startTime = { hours: Number(startTimeParts[0]), minutes: Number(startTimeParts[1]) };
                schedule.endTime = { hours: Number(endTimeParts[0]), minutes: Number(endTimeParts[1]) };
            });
            contract.startPeriod = new Date(contract.startPeriod * 1000);
            contract.endPeriod = new Date(contract.endPeriod * 1000);
            return contract;
        };

        if (props.id === 'create') {
            contract.value.assets = store.state.cart;
            if (contract.value.assets.length === 0) router.push('/overview');
        } else {
            loading.value = true;
            ContractsAPI.get(props.id)
                .then(res => {
                    contract.value = initializeContract(res.data);
                    loading.value = false;
                })
                .catch(() => (loading.value = false));
        }

        const pageTitle = computed(() => {
            if (!contract.value.status) return 'Create Contract';
            else return `Contract #${contract.value.id}`;
        });

        const addSchedule = () => {
            contract.value.weekDaysAndTimes.push({
                weekDay: null,
                startTime: {
                    hours: null,
                    minutes: null,
                },
                endTime: {
                    hours: null,
                    minutes: null,
                },
            });
        };

        const removeSchedule = idx => {
            contract.value.weekDaysAndTimes.splice(idx, 1);
        };

        const validateSchedules = validOperationAndFinancialDetails => {
            let valid = true;
            for (let i = 0; i < contract.value.weekDaysAndTimes.length; i += 1) {
                if (
                    contract.value.weekDaysAndTimes[i].weekDay === null ||
                    contract.value.weekDaysAndTimes[i].startTime.hours === null ||
                    contract.value.weekDaysAndTimes[i].startTime.minutes === null ||
                    contract.value.weekDaysAndTimes[i].endTime.hours === null ||
                    contract.value.weekDaysAndTimes[i].endTime.minutes === null
                ) {
                    valid = false;
                    break;
                }
            }
            if (validOperationAndFinancialDetails && !valid)
                toast.error('Some schedules are missing required information (Week Day/ Start Time/ End Time)');
            return valid;
        };

        const confirmAction = async actionMethod => {
            const { valid } =
                !contract.value.status || negotiating.value ? await form.value.validate() : { valid: true };
            const validSchedules = !contract.value.status ? validateSchedules(valid) : true;
            if (valid && validSchedules) {
                action.value = actionMethod;
                if (isBlockchainEnabled.value) showUnlockWalletModal.value = true;
                else actionMethod();
            }
        };

        // Can negotiate if the user is not the last negotiator and contract is on offer or negotiate status
        const allowNegotiationActions = computed(() => {
            const isLastNegotiatorManager = contract.value.utilization.length % 2 === 0;
            return (
                ((isLastNegotiatorManager && isAggregator) || (!isLastNegotiatorManager && !isAggregator)) &&
                (contract.value.status === ContractStatus.offer ||
                    contract.value.status === ContractStatus.negotiate) &&
                !negotiating.value
            );
        });

        const maxTotalActivations = computed(() => {
            if (
                contract.value.startPeriod &&
                contract.value.endPeriod &&
                negotiable.dailyActivations &&
                Number(negotiable.dailyActivations) > 0
            ) {
                const difference = contract.value.endPeriod.getTime() - contract.value.startPeriod.getTime();
                const days = Math.ceil(difference / (1000 * 3600 * 24));
                return Number(negotiable.dailyActivations) * days;
            }
            return Number.MAX_VALUE;
        });

        // CONTRACT ACTIONS
        const sendContract = async wallet => {
            showLoadingModal.value = true;
            const payload = prepareContractPayload(contract.value, negotiable);

            if (isBlockchainEnabled.value) {
                try {
                    payload.ethAddress = await createContract(wallet, payload);
                } catch (e) {
                    toast.error('Writing to blockchain failed');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                ContractsAPI.create(payload)
                    .then(res => {
                        contract.value = initializeContract(res.data);
                        showLoadingModal.value = false;
                        clearCart();
                        toast.success('Contract sent!');
                    })
                    .catch(e => {
                        showLoadingModal.value = false;
                        toast.error(
                            e.response.status === 400 ? e.response?.data?.message : 'Writing to database failed',
                        );
                    });
            }
        };

        const cancelNegotiation = () => {
            initializeNegotiations(contract.value);
            negotiating.value = false;
        };

        const declineContract = async wallet => {
            showLoadingModal.value = true;
            const newStatus = ContractStatus.rejected;
            if (!isAggregator) {
                if (isBlockchainEnabled.value) {
                    try {
                        await reject(wallet, contract.value.ethAddress);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.decline(contract.value.uuid)
                        .then(() => {
                            contract.value.status = newStatus;
                            showLoadingModal.value = false;
                            toast.success('Contract rejected!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            } else {
                if (isBlockchainEnabled.value) {
                    try {
                        await rejectOffer(wallet, contract.value.ethAddress);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.declineCounterOffer(contract.value.uuid)
                        .then(() => {
                            contract.value.status = newStatus;
                            showLoadingModal.value = false;
                            toast.success('Contract rejected!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            }
        };

        const acceptContract = async wallet => {
            showLoadingModal.value = true;
            const newStatus = ContractStatus.active;
            if (!isAggregator) {
                if (isBlockchainEnabled.value) {
                    try {
                        await accept(wallet, contract.value.ethAddress);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.activate(contract.value.uuid)
                        .then(() => {
                            contract.value.status = newStatus;
                            showLoadingModal.value = false;
                            toast.success('Contract accepted!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            } else {
                if (isBlockchainEnabled.value) {
                    try {
                        await acceptOffer(wallet, contract.value.ethAddress);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.activateCounterOffer(contract.value.uuid)
                        .then(() => {
                            contract.value.status = newStatus;
                            showLoadingModal.value = false;
                            toast.success('Contract accepted!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            }
        };

        const submitNegotiation = async wallet => {
            showLoadingModal.value = true;
            negotiating.value = false;
            const payload = prepareContractPayload(contract.value, negotiable);

            if (!isAggregator) {
                if (isBlockchainEnabled.value) {
                    try {
                        await negotiate(wallet, payload);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                        cancelNegotiation();
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.negotiate(contract.value.uuid, payload)
                        .then(res => {
                            contract.value = initializeContract(res.data);
                            showLoadingModal.value = false;
                            toast.success('Contract negotiation sent!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            } else {
                if (isBlockchainEnabled.value) {
                    try {
                        await counterOffer(wallet, payload);
                    } catch {
                        toast.error('Writing to blockchain failed');
                        showLoadingModal.value = false;
                        cancelNegotiation();
                    }
                }
                if (showLoadingModal.value) {
                    ContractsAPI.counterOffer(contract.value.uuid, payload)
                        .then(res => {
                            contract.value = initializeContract(res.data);
                            showLoadingModal.value = false;
                            toast.success('Contract negotiation sent!');
                        })
                        .catch(() => {
                            showLoadingModal.value = false;
                            toast.error('Writing to database failed');
                        });
                }
            }
        };

        const downloadPDF = () => {
            const fileLink = document.createElement('a');
            fileLink.href = `${process.env.VUE_APP_BACKEND_URL}/api/contracts/${contract.value.uuid}/pdf`;
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
        };

        const calculateAfterStartPeriodDate = computed(() => {
            return new Date(
                dayjs(new Date(contract.value.startPeriod))
                    .add(1, 'day')
                    .format(),
            );
        });

        const calculateBeforeEndPeriodDate = computed(
            () =>
                new Date(
                    dayjs(new Date(contract.value.endPeriod))
                        .subtract(1, 'day')
                        .format(),
                ),
        );

        return {
            dayjs,
            form,
            router,
            isAggregator,
            validationSchema,
            showUnlockWalletModal,
            showLoadingModal,
            negotiating,
            pageTitle,
            contract,
            weekDayOptions,
            negotiable,
            previousNegotiations,
            addSchedule,
            removeSchedule,
            ContractStatus,
            sendContract,
            confirmAction,
            isBlockchainEnabled,
            modalMessage,
            allowNegotiationActions,
            formatTime,
            statusClasses,
            cancelNegotiation,
            declineContract,
            acceptContract,
            submitNegotiation,
            action,
            maxTotalActivations,
            downloadPDF,
            loading,
            calculateAfterStartPeriodDate,
            calculateBeforeEndPeriodDate,
            enableNegotiate,
            enableDownloadPDF,
        };
    },
});
</script>
